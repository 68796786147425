<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="-mb-30 w-full h-[calc(100vh-150px)]">
        <div class="w-full h-full relative" style="opacity: 1">
          <div class="z-10 absolute top-0 left-0 w-full h-full">
            <div autoplay="" style="width: 100%; height: 100%">
              <!-- <video
                src="https://www.datocms-assets.com/65181/1655993179-investment_management_family_adv_bg_vid_002.mp4"
                preload="auto"
                autoplay=""
                loop=""
                crossorigin="anonymous"
                class="object-cover"
                playsinline=""
                webkit-playsinline=""
                x5-playsinline=""
                style="width: 100%; height: 100%"
              ></video> -->
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/FamilyAdvisory/b1.png')" alt="" />
            </div>
          </div>
          <div class="z-30 relative w-full h-full">
            <section
              class="absolute left-[40px] right-[40px] bottom-[100px] md:bottom-auto md:top-1/2 md:left-[70px] lg:left-[180px] md:-translate-y-1/2 md:max-w-[480px]"
            >
              <div class="flex flex-col space-y-22">
                <h1
                  class="text-2xl md:text-4xl text-2xl md:text-4xl text-iblack-300"
                  style="opacity: 1; transform: none"
                >
                  Our Family Advisory Extends Uncommon Care to Every Part of Life
                </h1>
                <p class="text-iblack-600 text-lg text-iblack-400" style="opacity: 1; transform: none">
                  On a foundation of deep trust, we strive to strengthen the capital, philanthropy, and legacy of our
                  community.
                </p>
                <div style="opacity: 1; transform: none"></div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
